import React from 'react';
import SubPageLayout from '../components/templates/SubPageLayout';
import Layout from '../components/templates/Layout';
import TargetsPage from '../components/pages/TargetsPage';

const Targets = () => (
  <Layout>
    <SubPageLayout title="줄눈시공대상">
      <TargetsPage />
    </SubPageLayout>
  </Layout>
);

export default Targets;
